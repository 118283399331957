* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    background: transparent;
    font-family: Futura, sans-serif;
}

@font-face {
    font-family: Futura;
    src: url('../assets/fonts/Futura.woff') format("WOFF");
}

body {
    background: rgb(30, 30, 30);
    color: white;
    overflow: hidden;
    overflow-y: scroll;
}

$colors: (
    "primary": #0F39FF,
);

$background: rgb(30, 30, 30);