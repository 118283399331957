@use "sass:map";
@import "../../sass/variables.scss";

.about {
    width: 100vw;
    max-width: 65rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;

    &-title {
        color: map.get($colors, primary);
        font-size: 3rem;
        font-weight: 600;
        padding-top: 3rem;
        margin-bottom: 1.5rem;
    }

    &-description {
        font-weight: 400;
        font-size: 1rem;
        max-width: 51rem;
        padding: 0 2rem;
    }

    &-bars {
        width: 100vw;
        max-width: 80%;
        margin: 1.5rem;
        text-align: left;

        &-bar {
            display: flex;
            margin: 1rem;
            padding: 1.5rem 2rem;
            border-radius: 0.5rem;
            background: rgb(50, 50, 50);

            &-image {
                object-fit: cover;
                height: 4rem;
                margin-right: 2rem;
            }

            &-text {
                p {
                    font-size: 0.9rem;
                    font-weight: 200;
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .about {
        &-bars {
            &-bar {
                &-image {
                    width: 2.25rem;
                    height: 2.25rem;
                }

                &-text {
                    h2 {
                        font-size: 5vw;
                    }

                    p {
                        font-size: 3vw;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 720px) {
    .about {
        &-bars {
            &-bar {
                &-text {
                    p {
                        font-size: 2vw;
                    }
                }
            }
        }
    }
}