@use "sass:map";
@import "../../sass/variables.scss";

.contact {
    width: 100vw;
    max-width: 60rem;
    min-height: calc(100vh - 7rem);
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-title {
        font-size: 3rem;
        margin-bottom: 1rem;
    }

    &-description {
        padding: 1rem;
        font-size: medium;
        font-weight: 300;
    }

    &-form {
        width: 90vw;
        max-width: 60rem;
        margin: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &-name, &-email, &-message {
            width: 100%;
            max-width: 40rem;
            margin: 0.5rem;
            padding: 0.5rem 1rem;
            color: white;
            border: none;
            border-radius: 0.5rem;
            background: rgb(40, 40, 40);
            font-size: medium;
        }

        &-submit {
            background: white;
            border: none;
            border-radius: 0.5rem;
            margin: 2rem;
            padding: 0.75rem 3.5rem;
        }

        &-links {
            display: flex;
            flex-wrap: wrap;

            &-link {
                width: 3rem;
                height: 3rem;
                margin: 0 0.75rem;
                object-fit: cover;
            }
        }
    }
}