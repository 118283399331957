@use "sass:map";
@import "../../sass/variables.scss";

.navigation {
    background: $background;
    width: 100vw;
    max-width: 75rem;
    height: 7rem;
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;

    &-logo {
        width: 10rem;
        height: 10rem;
        object-fit: cover;
    }

    &-desktopMenu {

        &-listItem {
            margin: 1rem;
            color: white;
            cursor: pointer;
            &:hover {
                color: map.get($colors, primary);
                padding-bottom: 0.5rem;
                border-bottom: 3px solid map.get($colors, primary);
            }
        }

        &-select {
            width: 150px;
            color: white;
            border: none;
            padding: 0.5rem 3rem;
            margin: 0.25rem;
            background: rgb(30, 30, 30);
        }

        &-button {
            background: white;
            color: black;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 1rem;
            border-radius: 2rem;
        }

        &-image {
            object-fit: cover;
            width: 1rem;
            height: 1rem;
            margin: 1rem;
        }
    }

    &-mobileMenu {
        display: none;
        object-fit: cover;
        height: 1.8rem;

        &-navMenu {
            position: absolute;
            top: 5rem;
            right: 2rem;
            z-index: 2;
            display: flex;
            flex-direction: column;
            padding: 0.5rem;
            height: fit-content;
            min-width: 15rem;
            background: rgb(40, 40, 40);
            border-radius: 1rem;

            &-select {
                color: white;
                border: none;
                padding: 0.5rem 3rem;
                margin: 0.25rem;
                background: rgb(30, 30, 30);
            }

            &-listItem {
                color: white;
                padding: 0.5rem 3rem;
                margin: 0.25rem;
                background: rgb(30, 30, 30);
            }
        }
    }

    .active {
        color: map.get($colors, primary);
        padding-bottom: 0.5rem;
        border-bottom: 3px solid map.get($colors, primary);
    }
}

@media screen and (max-width: 720px) {
    .navigation {
        &-desktopMenu {
            display: none;

            &-language {
                display: none;
            }

            &-button {
                display: none;
            }
        }

        &-mobileMenu {
            display: flex;
        }
    }
}