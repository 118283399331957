@use "sass:map";
@import "../../sass/variables.scss";

@media screen and (max-width: 480px) {
    .intro {

        &-background {
            right: -20vw;
        }
    }
}

@media screen and (max-width:840px) {
    .intro {

        &-background {
            right: -10vw;
        }

        &-content {
            font-size: 10vw;

            &-hello {
                font-size: 4.5vw;
            }
        }
    }
}

.intro {
    width: 100vw;
    max-width: 75rem;
    height: calc(100vh - 7rem);
    margin: 0 auto;
    overflow: hidden;
    position: relative;

    &-background {
        height: 50vh;
        display: flex;
        position: absolute;
        top: 7rem;
        right: 0;
        z-index: -1;
        object-fit: cover;
    }

    &-content {
        width: 100vw;
        height: 100vh;
        padding: 2rem;
        font-size: 3rem;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &-hello {
            font-size: 1.75rem;
            font-weight: 100;
        }

        &-introName {
            color: map.get($colors, primary);
        }

        &-introText {
            font-size: medium;
            font-weight: 100;
            letter-spacing: 1px;
        }

        &-button {
            background: white;
            margin: 1rem 0;
            padding: 0.75rem 1.5rem;
            border: none;
            border-radius: 2rem;
        }
    }
}